<template>
  <div class="class_details_wrap">
    <div
      class="class_details_title"
      v-if="type === 'curriculum'">
      <span class="date">日期</span>
      <span class="textbook_content">教材内容</span>
    </div>
     <div
      class="class_details_title class_details_outline"
      v-else>
      <span class="num">序号</span>
      <span class="name">课程名称</span>
      <span class="name">授课老师</span>
      <span class="count">课时</span>
      <span class="status">上课状态</span>
      <span class="operation">操作</span>
    </div>
    <el-collapse v-model="dayId" accordion class="class_details_collapse" @change="change">
      <el-collapse-item 
        v-for="(item, idx) in list"
        :key="item.id"
        :name='item.id'
        class="el-collapse-item_stu"
      >
        <template #title>
          <span class="theday" v-if="type === 'curriculum'">第{{ item.days }}天
            <span class="that_date">{{ item.date }}</span>
          </span>
          <div v-else class="class_details_collapse_title" @click="getTeachingList(item)">
            <span class="num">{{ idx + 1 }}</span>
            <span class="name">
              <el-tooltip class="item course_name" effect="dark" :content="item.name" placement="top-start">
                <p>{{String(item.name).substring(0,20)}}</p>
              </el-tooltip></span>
            <div class="students"><span class="studentson" v-for="(v,i) in item.teaList" :key="i">{{ v.teaName }}{{' '}}</span></div>
            <span class="count">{{ item.count }}</span>
            <span class="status">
              <span v-if="item.status=='已完课'">{{ item.status }}</span><span v-if="item.status=='上课中'" style="color:red">{{ item.status }}</span><span v-if="item.status=='待上课'" style="color:#FF7C02">{{item.status}}</span>
            </span>
            <span @click="getTeachingList(item)">课程资料</span>
          </div>
        </template>
        <Info v-if="type === 'curriculum' &&  dayId === item.id" :list="dayList" :type="type" :outLineList="item.infos" :gradeId='gradeId'/>
        <Info v-if="type === 'outline'" :list="studentsList" :type="type" :outLineList="item.infos" :gradeId='gradeId'/>
      </el-collapse-item> 
    </el-collapse>
  </div>
  
</template>

<script>
const WEEK_CN = { 
  1: '一', 2: '二', 3: '三', 4: '四', 5: '五', 6: '六', 7: '七', 8: '八', 9: '九', 10: '十', 
  11: '十一', 12: '十二', 13: '十三', 14: '十四', 15: '十五', 16: '十六', 17: '十七', 18: '十八', 19: '十九', 20: '二十',
  21: '二十一', 22: '二十二', 23: '二十三', 24: '二十四', 25: '二十五', 26: '二十六', 27: '二十七', 28: '二十八', 29: '二十九', 30: '三十',
  31: '三十一', 32: '三十二', 33: '三十三', 34: '三十四', 35: '三十五', 36: '三十六', 37: '三十七', 38: '三十八', 39: '三十九', 40: '四十',
  41: '三十一', 42: '三十二', 43: '三十三', 44: '三十四', 45: '三十五', 46: '三十六', 47: '三十七', 48: '三十八', 49: '三十九', 50: '五十',
}


import Info from './Info'
import {DAYDETAIL,GETCOURSESTUDETAIL} from '@/server/details'

export default {
  data() {
    return {
      dayId: '',
      gradeConfId: 0,
      WEEK_CN,
      dayList: [],
      studentsList:[]
    };
  },
  components: {
    Info,
  },
  props: ['list', 'day', 'id', 'type','gradeId'],
  watch: {
    day () {
      if (this.day.length) {
        this.dayList = this.day
        this.dayId = this.id
      }
    },
    id() {
      this.dayId = this.id
    },

    list() {
      if (this.type === 'outline') this.dayId = this.list[0].id
    },
    list: function(newVal, oldVal){
      if(sessionStorage.getItem('stuTabIndex')) {
        this.change(sessionStorage.getItem('stuTabIndex'))
      } else {
        this.change(newVal[0].id)
      }
    }
  },
  methods: {
    change(val) {
      if(val !== undefined) {
        sessionStorage.setItem('stuTabIndex',val)
      }
      this.dayId = val
      this.list.filter(v=>{
        if(v.id == val){
          this.getClassDetail(v.sid)
        }
      })
    },
    // 根据天的ID 取详情
    getClassDetail(sid) {
        DAYDETAIL({daySId: sid,gradeId:this.gradeId}).then(res=>{
          if (res.data.code === 0) {
            this.dayList = res.data.data
          }
        })
    },
    //下拉
    getTeachingList(val) {
      GETCOURSESTUDETAIL({gradeId:this.$route.query.id,name:val.name}).then(res=>{
        console.log(res.data.data);
        if(res.data.code==0) {
          this.studentsList = res.data.data
        }
      })
    },
  },
  created() {
    // this.gradeConfId = this.$route.query.id || JSON.parse(sessionStorage.getItem('gradeConfInfo')).id
    // this.dayList = data.data
    if(!!this.list && this.list.length>0 ){
      this.dayId = this.list[0].id
      // if(!!this.dayId){
      //    this.list.filter(v=>{
      //     if(v.id == this.dayId){
      //       this.getClassDetail(v.sid)
      //     }
      //   })
      // }
    } 
      
  }
}
</script>

<style lang="scss">
.class_details_wrap {
  background-color: #FAFAFA;
  .class_details_title {
    height: 60px;
    line-height: 60px;
    padding-left: 31px;
    font-size: 16px;
    @include border();
    border-bottom: none;
    color: $font_color_grey_3;

    span {
      margin-right: 108px;
    }
  }

  .el-collapse-item.is-active {
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      background-color: #FAFAFA !important;
    }
    .el-collapse-item__arrow {
      transform: rotate(-90deg);
    }
  }

  .el-collapse-item {
    @include border_item();
    @include border_item(right);
    
    .el-collapse-item__header,
    .el-collapse-item__wrap {
      padding-left: 31px;
      // padding-right: 31px;
    }

    .el-collapse-item__header {
      height: 60px;
      // line-height: 80px;
      font-size: 16px;
      font-weight: 500;
    }

    .el-collapse-item__header.is-active {
      color: #333;
    }

    .el-collapse-item__content {
      padding-bottom: 15px;
      padding-top: 28px;
    }
  }

  .el-collapse-item__arrow {
    margin: 0 10px;
    line-height: 52px !important;
    transform: rotate(90deg);
    color: #AAAAAA;
    font-size: 14px;
  }

  .class_details_outline {
    span {
      margin-right: 0;
    }
  }

  .class_details_outline,
  .class_details_collapse_title {
    display: flex;
    font-size: 16px;
    font-family: PingFangSC-Medium, PingFang SC;
    font-weight: 500;
    color: #333333;
    .num {
      width: 100px;
      color: #999;
    }

    .name {
      width: 190px;
      color: #999;
      display: flex;
      align-items: center;
      .course_name{
        line-height: 26px;
        color: #333;
      }
      .item{
        font-size: 16px;
      }
    }

    .count {
      width: 130px;
      color: #999;
    }

    .date {
      width: 165px;
    }
    .students {
      width: 200px;
      font-size: 16px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
    .status{
      width: 380px;
      color: #999;
    }
    .operation{
      color: #999;
    }
  }
  
}
.class_details_wrap .el-collapse-item .el-collapse-item__header.is-active{
  height: auto;
}
.class_details_wrap .el-collapse-item_stu .el-collapse-item__header.is-active{
  height: 60px;
  border-bottom: 1px solid #fff;
}
.class_details_wrap .el-collapse-item_stu:last-child{
  margin-bottom: 0;
}
.class_details_wrap .el-collapse-item_stu{
  // .class_info_right .class_info_item li .class_info_item_btns{
  //   padding-top: 9px;
  // }
  .el-collapse-item__wrap{
    // padding-bottom: 25px;
    .class_info_wrap{
      .el-timeline-item{
        padding-bottom: 0;
      }
      // .el-timeline-item{
      //   .el-timeline-item__wrapper{
      //     .el-timeline-item__content{
      //       div:nth-child(2){
      //         padding-top: 8px;
      //       }
      //     }
      //   }
      // }
      .el-timeline-item:first-child{
        .el-timeline-item__wrapper{
          .el-timeline-item__content{
            div:nth-child(2){
              // padding-top: 20px;
            }
            .class_info_left{
              top:27px;
              width: 104px;
              overflow: hidden;
            }
          }
        }
      }
      .el-timeline-item{
        min-height: 100px;
      }
      .el-timeline-item:last-child{
        .el-timeline-item__content{
          .class_info_right:last-child{
            border-bottom: none;
          }
        }
      }
    }
  }
  .class_info_wrap{
    .el-timeline-item__node--normal{
      left: 118px;
      top: 20px;
      z-index: 99;
      width: 12px;
      height: 12px;
      border: 1px solid #DDDDDD;
    }
    .teaching_program{
      .el-timeline-item__node--normal{
        top: 10px;
      }
      .el-timeline-item__tail{
        top: 20px;
      }
    }
    .el-timeline-item__tail{
      left: 124px;
      top: 30px;
      border-left: 1px solid #DDDDDD;
      z-index: 99;
    }
  }
  // margin-top: 27px;
  .class_info_left{
    top: 14px;
    span{
      width: auto;
    }
    .time{
      font-size: 16px;
      line-height: 28px;
      margin-bottom: 4px;
    }
    .item{
      font-size: 16px;
      font-weight: 500;
      // width: 120px;
      margin-top: 4px;
      // word-break: keep-all;
      // white-space: nowrap;
      // overflow: hidden;
      // text-overflow: ellipsis;
    }
    .name{
      font-size: 16px;
    }
  }
  .class_info_right{
    margin-left: 117px;
    padding-top: 0;
    padding-bottom: 0;
    border: none;
    min-height: 40px;
    display: flex;
    align-items: center;
    padding-left: 23px;
  }
  // .class_info_right:last-child{
  //   // border-bottom: 1px solid #e6e6e6;
  //   padding-bottom: 20px;
  // }
  .el-timeline-item__wrapper{
    padding-left: 9px;
  }
}
</style>
<style lang="scss" scoped>
.class_details_wrap {
  .class_details_title{
    .date{
      font-size: 16px;
    }
    .textbook_content{
      font-size: 16px;
    }
  }
  .class_details_collapse{
    border-top: 1px solid #E6E6E6;
  }
}
.theday{
  margin-left: 10px;
  .that_date{
    font-size: 14px;
    font-weight: 400;
    color: #999999;
    line-height: 20px;
    background: #F8F8F8;
    padding: 0 4px;
    height: 20px;
    line-height: 20px;
    margin-left: 5px;
  }
}
</style>