<!--  -->
<template>
  <div>
    <Menu :menuTitle="classInfo.gradeName"/>
    <!-- <el-button
      style="position: relative; z-index: 99; margin-top: 20px"
      @click="goBack"
      >返回首页</el-button> -->
    <div class="details_wrap">
      <div class="content">

        <!-- <div  v-if="waitingTest" class="state_exam">
          <div class="left">
            <span class="el-icon-warning"></span>
            <span clang="left_tip">您有考试待完成</span>
          </div>
          <span class="right_btn" @click="getWaitingTestData">进入考试>></span>
        </div> -->
        <ul class="details_class_info">
          <p style="position: absolute;right: 34px;font-size:16px">
            <img  v-if="isEdit == true" src="../../assets/images/icon_edit.png" alt="" @click="edit" style="width:19.6px;height:19.6px;color:#2D70F8">
            <!-- <i v-if="isEdit == 'pre'" class="el-icon-edit-outline" ></i> -->
            <!-- <svg v-if="isEdit == 'pre'" class="icon-svg" aria-hidden="true" @click="edit" style="width:1.5rem;height:1.5rem">
                <use xlink:href="#icon_editor"></use>
                <i class="el-icon-edit-outline"></i> 
              </svg> -->
          </p>
          <li>
            <span class="details_class_info_label">班级名称：</span>
            <span>{{ classInfo.gradeName }}</span>
          </li>
          <li>
            <span class="details_class_info_label">开班时间：</span>
            <span>{{ classInfo.openGradeTime }} - {{ classInfo.endGradeTime }}</span>
          </li>
          <li>
            <span class="details_class_info_label">班主任：</span>
            <span>{{ classInfo.masterName }}</span>
            <!-- <span style="margin-left:30px">
              <svg v-if="inClass == 0" class="icon-svg" aria-hidden="true" @click="edit">
                <use xlink:href="#icon_editor"></use>
              </svg>
            </span> -->
          </li>
          <li>
            <span class="details_class_info_label">班级属性：</span>
            <span>{{ classInfo.gradeConfName }}</span>
          </li>
          <li style="width: auto;max-width: 750px;">
            <span class="details_class_info_label">班级讲师：</span>
            <span v-for="(n, i) in classInfo.teaLists" :key="n + i">{{n.teaName}}{{i == classInfo.teaLists.length -1 ? '' : '、'}}</span>
            <!-- <ul :class="{details_class_info_child: true, height: foldFlag_teacher}"> -->
              <!-- <li v-for="(n, i) in classInfo.teaLists" :key="n + i">{{n.teaName}}{{i == classInfo.teaLists.length -1 ? '' : '、'}}</li> -->
              <!-- <li v-show="foldFlag_teacher" class="details_class_info_btn" @click="fold_teacher">收起</li> -->
              
            <!-- </ul> -->
            <!-- <p class="details_class_info_btn show" @click="fold_teacher">收起</p> -->
          </li>
          <li class="details_class_info_member">
            <span class="details_class_info_label">班级成员：</span>
            <ul :class="{details_class_info_child: true, height: foldFlag}" style="margin-right: 100px;">
              <template v-if="classInfo.stuLists.length>13 && foldFlag">
                <li v-for="(n, i) in classInfo.stuLists.slice(0,14)" :key="n + i">
                  {{n.stuName}}{{i == 13 ? '' : '、'}} 
                  <span v-if="i == 13">{{`等${classInfo.stuLists.length}人`}}</span>
                </li>
              </template>
              <template v-else>
                <li v-for="(n, i) in classInfo.stuLists" :key="n + i">
                  {{n.stuName}}{{i == classInfo.stuLists.length -1 ? '' : '、'}} 
                </li>
              </template>
              <!-- <li v-show="foldFlag" class="details_class_info_btn" @click="fold">{{foldFlag?'收起':'展开'}}</li> -->
              <!-- <li class="details_class_info_btn show" @click="fold">{{foldFlag?'收起':'展开'}}</li> -->
            </ul>
            <p class="details_class_info_btn show" @click="fold" v-if="classInfo.stuLists && classInfo.stuLists.length>13">{{foldFlag?'展开':'收起'}}</p>
          </li>

          <!-- <li class="details_class_info_edit">
            <svg class="icon-svg" aria-hidden="true" style="color: #8C8C8C;margin-right:17px;">
              <use xlink:href="#icon_editor"></use>
            </svg>
          </li> -->
        </ul>

        <div class="details_content">
          <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="课程详情" name="curriculum">
              <Details :type="activeName" :list="list" :gradeId="gradeId" />
            </el-tab-pane>
            <el-tab-pane label="教学大纲" name="outline">
              <Details
                :type="activeName"
                :list="studentList"
                :gradeId="gradeId"
              />
            </el-tab-pane>
            <el-tab-pane label="智能陪练" name="player">
              <div class="class__pl_title">
                <span class="class__pl_item">类型</span>
                <span class="class__pl_item--num">数量</span>
                <span class="class__pl_item--controll">操作</span>
              </div>
              <div class="class__pl_content">
                <el-collapse accordion>
                  <el-collapse-item
                    v-for="(item, index) in AIListLevelQueryAndRes.list"
                    :key="'ail_' + index"
                    @click="LevelClick(item, index)"
                  >
                    <template #title>
                      <div class="class__pl_detailTitle">
                        <span class="class__detailTitle">{{
                          item.levelName
                        }}</span>
                        <span class="class__detailTitle--num">{{
                          item.count
                        }}</span>
                      </div>
                    </template>
                    <div
                      class="class__content_table"
                      v-if="AIListDetailQueryAndRes.list[index]"
                    >
                      <div class="class__table_col">
                        <span class="class__table_item class__table_item--first"
                          >陪练名称</span
                        >
                        <span class="class__table_item">陪练次数</span>
                        <span class="class__table_item">平均成绩</span>
                        <span class="class__table_item">最高成绩</span>
                        <span class="class__table_item">最新成绩</span>
                        <span class="class__table_item"></span>
                      </div>
                      <div
                        class="class__table_col class__table_content"
                        v-for="(item, idx) in AIListDetailQueryAndRes.list[
                          index
                        ]"
                        :key="'idx_' + idx"
                      >
                        <span class="class__table_item class__table_item--first"
                          ><span class="table__rank_tag">{{ idx + 1 }}</span>
                          {{ item.name }}</span
                        >
                        <span class="class__table_item">{{
                          item.trainCount
                        }}
                        <img v-if="item.trainCount != '0'" class="icon_record" @click="goRecordList(item)" src="../../assets/images/icon_record.png">
                        </span>
                        <span class="class__table_item"
                          >{{ item.avgScore }}分</span
                        >
                        <span class="class__table_item"
                          >{{ item.maxScore }}分</span
                        >
                        <span class="class__table_item table_state_new"
                          >{{ item.lastScore }}分</span
                        >
                        <span
                          class="class__table_item"
                          style="justify-content: flex-end"
                        >
                          <span
                            class="class__table_btn"
                            :class="{ state__has: item.trainCount == 0 }"
                            @click="toTest(item)"
                            >陪练</span
                          >
                        </span>
                      </div>
                    </div>
                  </el-collapse-item>
                </el-collapse>
                <div
                  v-show="AIListLevelQueryAndRes.list.length == 0"
                  class="nodata"
                >
                  暂无
                </div>
              </div>
            </el-tab-pane>
          </el-tabs>

          <div class="details_btns">
            <span @click="goRankingList">
              <img  src="../../assets/images/icon_ranking.png" alt="" style="margin-top: -5px;width:23px;vertical-align: middle;color:#2D70F8"> 
            排行榜
            </span>

            <span @click="toTimetable">
              <img  src="../../assets/images/icon_course.png" alt="" style="margin-top: -5px;width:21.8px;vertical-align: middle;color:#2D70F8"> 
            课程表
            </span>
          </div>
        </div>
      </div>
      <div class="wrong_questions" @click="toWrong">
        <span class="el-icon-error"></span>
        <span class="text">我的错题</span>
      </div>
      <el-dialog
        v-model="dialogVisible"
        title="选择标签"
        custom-class="select_lable"
      >
        <div class="items">
          <div class="item" v-for="(item, index) in labelsData.value" :key="index">
            <span class="title">{{item.label}}</span>
            <el-radio-group v-model="radios[index].radio">
              <el-radio-button :label="itemlist.label" v-for="(itemlist, indexs) in item.list" :key="indexs"></el-radio-button>
            </el-radio-group>
          </div>
        </div>

        <template #footer>
          <span class="dialog-footer">
            <el-button @click="cancelBtn">取消</el-button>
            <el-button @click="randomBtn">随机模式</el-button>
            <el-button type="primary" @click="determineBtn">确定</el-button>
          </span>
        </template>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import Menu from '@/components/Menu'
import Details from "./components/Details/index.vue";
import { COURSEDAYS, GETCLASSDETAIL, GETCOURSENAME } from "@/server/details";
import { onMounted, reactive, ref } from "vue";
import {useRoute} from 'vue-router'
import { WaitingTest } from "@/server/details";
import { ListMyWrongQu } from "@/server/details";
import { ElMessage } from 'element-plus'
let pageApp = null;
export default {
  setup() {
    const dialogVisible = ref(false)
    const labelsData = reactive([])
    const radios = reactive([]);
    var aiQuery = reactive({});
    const route = useRoute()
    const AIListLevelQueryAndRes /* AI等级列表 */ = reactive({
      api: "/corporate/university/grade/ai/level/list",
      query: { gradeId: "1433628843933765634" },
      list: [
        {
          count: 10,
          level: 0,
          levelName: "初级陪练11",
        },
      ],
    });
    const AIListDetailQueryAndRes /* AI等级详情列表 */ = reactive({
      api: "/corporate/university/grade/ai/list",
      query: { gradeId: "1433628843933765634", level: 0 },
      list: [
        {
          avgScore: 0, // 平均成绩
          gradeConfId: 0, //课程ID
          gradeId: 0, // 班级ID
          id: 0, // ai陪练id
          lastScore: 0, // 最新成绩
          maxScore: 0, // 最高成绩
          name: 0, // 名称
          trainCount: 0, // 训练次数
          zjId: 0, // 竹间课程ID
        },
      ],
    });

    const methodsAIlist = {
      getAIListLeve() {
        pageApp.$http
          .post(AIListLevelQueryAndRes.api, AIListLevelQueryAndRes.query)
          .then((res) => {
            pageApp.AIListLevelQueryAndRes.list = res.data.data;
            // 生成详情列表
            pageApp.AIListDetailQueryAndRes.list = new Array(
              res.data.data.length
            );
          });
      },
    };

    const LevelClick = (item, index) => {
      if (AIListDetailQueryAndRes.list[index]) return;
      AIListDetailQueryAndRes.query.level = item.level;
      pageApp.$http
        .post(AIListDetailQueryAndRes.api, AIListDetailQueryAndRes.query)
        .then((res) => {
          pageApp.AIListDetailQueryAndRes.list[index] = res.data.data;
          pageApp.AIListDetailQueryAndRes.list =
            pageApp.AIListDetailQueryAndRes.list;
        });
    };

    const resetLabelsData = () => {
      labelsData.value.forEach((element1, index) => {
        element1.list.forEach(element => {
          element.select = false
        });
      });
      sessionStorage.setItem("labelsData", JSON.stringify(labelsData.value));
    }

    const randomBtn = () => {
      resetLabelsData()
      for (let i = 0; i < labelsData.value.length; i++) {
        labelsData.value[i].list[Math.floor(Math.random() * (labelsData.value[i].list.length))].select = true
      }
      sessionStorage.setItem("labelsData", JSON.stringify(labelsData.value));
      dialogVisible.value = false
      JumpToTest(aiQuery)
    }
    const determineBtn = () => {
      resetLabelsData()
      console.log(radios)
      labelsData.value.forEach((element1, index) => {
        element1.list.forEach(element => {
          if(element.label == radios[index].radio) {
            element.select = true
          }
        });
      });
      sessionStorage.setItem("labelsData", JSON.stringify(labelsData.value));
      dialogVisible.value = false
      JumpToTest(aiQuery)
    }
    const cancelBtn = () => {
      dialogVisible.value = false
    }
    const labels =(query) => {
      aiQuery = query
      const datas = {
        "courseId": query.courseId,
        "publishId": query.zjId
      }
      pageApp.$http.post("/corporate/university/ai/train/practice/config/label", datas).then((res) => {
        radios.length = 0;
        if (res.data.data && res.data.data.length > 0) {
          dialogVisible.value = true
          labelsData.value = res.data.data
          for (let i = 0; i < res.data.data.length; i++) {
            radios.push({radio: ''})
          }
        } else {
          JumpToTest(aiQuery)
        }
        // JumpToTest(query)
      }).catch(() => {})
    };

    const JumpToTest = (query) => {
      pageApp.$router.push({ path: "/practicePartner", query });
    };

    const toTest = (query) => {
      if (!sessionStorage.getItem("_token")) {
        pageApp.$http
          .post("/corporate/university/ai/train/zhujian/login")
          .then((res) => {
            const data = res.data.data;
            sessionStorage.setItem("_token", data.token);
            // sessionStorage.setItem("zjtenantId", data.tenantId);
            data.info.tenantId = data.tenantId
            sessionStorage.setItem(
              "zjloginInfo",
              JSON.stringify(data.info)
            );
            // JumpToTest(query);
            labels(query)
          });
      } else labels(query);
    };

    onMounted(() => {
      methodsAIlist.getAIListLeve();
    });

    return {
      AIListLevelQueryAndRes,
      AIListDetailQueryAndRes,
      LevelClick,
      toTest,
      dialogVisible,
      radios,
      labelsData,
      cancelBtn,
      determineBtn,
      randomBtn,
      resetLabelsData,
      aiQuery,
      // toWrong
    };
  },
  data() {
    return {
      classInfo: {
        arrangerTeacher: 0,
				endGradeTime: "2020.10.28 08:00",
				gradeConfId: 0,
				gradeConfName: "新生班",
				gradeName: "2020年第8期客服",
				id: 0,
				inClass: 0,
				masterId: 0,
				masterName: "田世宣",
				openGradeTime: "2020.10.26 08:00",
				peopleNum: 0,
        stuLists: []
      },
      foldFlag: true,
      activeName: "curriculum",
      list: [],
      gradeId: "",
      studentList: [],
      waitingTest: false,
      wrongQuestions: false,
      isEdit:''
    };
  },
  components: {
    Menu,
    Details,
  },
  methods: {
    toWrong() {
      if(this.wrongQuestions) {
        pageApp.$router.push({ path: "/selftest", query:{ type:'wrongQs',gradeId:this.$route.query.id } });
      } else {
        // alert('暂无错题')
        ElMessage.warning({
          message: '暂无错题',
          type: 'warning',
          customClass: 'wrong_message'
        });
      }
    },
    goBack() {
      sessionStorage.removeItem('stuTabIndex')
      this.$router.push({ path: "/studentlogin" });
    },
    // 展开 / 收起
    fold() {
      this.foldFlag = !this.foldFlag;
    },
    // 跳转至课表
    toTimetable() {
     if(this.$route.query.timeType == 0){
        this.$router.push({
          path: '/timetable',
          query: {
            id: this.gradeId,
            timeType:this.$route.query.timeType
          }
        })
      }else{
        this.$router.push({
        path: '/customTimetable',
        query: {
          id: this.gradeId,
          timeType:this.$route.query.timeType
        }
      })
      }
    },
    // 切换tab
    handleClick(tab) {
      const { paneName } = tab;
      this.activeName = paneName;
      if (paneName === "curriculum") {
        console.log(999);
      }

      if (paneName === "outline") {
        console.log(999);
      }
    },
    //去排行榜
    goRankingList() {
      this.$router.push({
        path: "/rankingListStu",
        query: { id: this.$route.query.id },
      });
    },
    goRecordList(query) {
      this.zjLogin(query)
      // this.$router.push({ path: "/recordList", query })
    },
    zjLogin(query) {
      if (!sessionStorage.getItem("_token")) {
        this.$http.post("/corporate/university/ai/train/zhujian/login")
          .then((res) => {
            const data = res.data.data;
            sessionStorage.setItem("_token", data.token);
            data.info.tenantId = data.tenantId
            sessionStorage.setItem(
              "zjloginInfo",
              JSON.stringify(data.info)
            );
            this.$router.push({ path: "/recordList", query })
          });
      } this.$router.push({ path: "/recordList", query })
    },
  },
  created() {
    pageApp = this;
    // 获取班级id
    this.gradeId = this.$route.query.id;
    this.AIListLevelQueryAndRes.query.gradeId = this.gradeId;
    this.AIListDetailQueryAndRes.query.gradeId = this.gradeId;
    if(localStorage.getItem('clickId') == JSON.parse(sessionStorage.getItem('loginInfo')).eId){
      this.isEdit = true
    }else{
      this.isEdit = false
    }
    // 获取班级上课天数
    COURSEDAYS({ gradeId: this.gradeId }).then((res) => {
      if (res.data.code === 0) {
        this.list = res.data.data;
      }
    });

    // 是否有错题
    ListMyWrongQu({
      gradeId: this.$route.query.id,
    }).then((res) => {
      if (res.data.code === 0) {
        if (res.data.data && res.data.data.length > 0) {
          this.wrongQuestions = true
        }
        //this.details = res.data.data
      }
    });

    // 是否有考试待完成
    WaitingTest({
      gradeId: this.$route.query.id,
    }).then((res) => {
      if (res.data.code === 0) {
        if (res.data.data) {
          this.waitingTest = true
        }
        //this.details = res.data.data
      }
    });

    // 获取班级详情
    GETCLASSDETAIL({ gradeId: this.gradeId }).then((res) => {
      if (res.data.code === 0) {
        // console.log(res);
        this.classInfo = res.data.data
        // this.$router.push({
        //   path: this.$route.path,
        //   query: {
        //     ...this.$route.query,
        //     gradeConfId: res.data.data.gradeConfId,
        //   },
        // });
      }
    });

    //获取学生课程名称
    GETCOURSENAME({ gradeId: this.gradeId }).then((res) => {
      console.log(res.data.data);
      if (res.data.code == 0) {
        this.studentList = res.data.data;
      }
    });
  },
};
</script>
<style lang='scss'>
.wrong_message{
  padding: 16px 22px !important;
}
.details_content{
  .el-collapse-item__header{
    height: 60px;
    padding-right: 20px;
  }
  .el-collapse-item__header.is-active{
    background: #fafafa;
    border-bottom: 1px solid #fff;
  }
  .class__table_col{
    font-size: 16px !important;
  }
  .el-collapse-item__content{
    padding-bottom: 0;
  }
}
</style>
<style lang='scss' scoped>
.details_class_info {
    display: flex;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-bottom: 20px;
    background-color: $bg_grey;
    padding: 10px 34px;
    position: relative;

    & > li {
      font-size: 16px;
      width: 356px;
      margin: 5px 0;
      line-height: 28px;
    }
  }

  .details_class_info_label {
    color: $font_color_grey_3;
  }

  .details_class_info_member {
    width: 100% !important;
    display: flex;
    
  }

  .details_class_info_child {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    position: relative;

    li {
      font-size: 16px;
      // margin-bottom: 10px;
    }
  }
.nodata {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  color: #666;
  font-size: 16px;
}
.table__rank_tag {
  display: flex;
  width: 22px;
  height: 22px;
  background: #2d70f8;
  color: #fff;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  margin-right: 8px;
  border-radius: 4px;
}
.table_state_new {
  color: #2d70f8;
}
.class__content_table {
  display: flex;
  flex-direction: column;
  padding: 30px 80px;
  background: #f8f8f8;
}
.class__table_btn {
  display: flex;
  width: 74px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border: 1px solid #2d70f8;
  border-radius: 4px;
  color: #2d70f8;
  background: #fff;
  cursor: pointer;
  transition: all 0.2s linear;
}
.class__table_btn:hover {
  background: #2d70f8;
  color: #fff;
}
.state__has {
  background: #2d70f8;
  color: #fff;
}
.state__has:hover {
  color: #2d70f8;
  background: #fff;
}
.class__table_col {
  display: flex;
  flex: 1;
  width: 100;
  font-size: 20px;
  color: #999;
}

.class__table_content {
  color: #333;
}
.class__table_item {
  display: flex;
  flex: 1;
  padding-bottom: 32px;
  align-items: center;
}
.class__table_item--first {
  flex: 0 0 230px;
}
.class__detailTitle {
  flex: 0 0 160px;
}
.class__detailTitle--num {
  flex: 0 0 40px;
}
.class__pl_detailTitle {
  display: flex;
  padding: 0 30px;
  font-weight: 600;
  font-size: 16px;
  color: #999;
  font-weight: normal;
}
.class__pl_content {
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.class__pl_title {
  font-size: 16px;
}
.class__pl_item {
  flex: 0 0 160px;
  margin-left: 31px;
}
.class__pl_item--num {
  flex: 0 0 40px;
}
.class__pl_item--controll {
  flex: 1;
  text-align: right;
  margin-right: 54px;
}
.class__pl_title {
  display: flex;
  border: 1px solid #E6E6E6;
  // padding: 16px 30px;
  color: #999;
  height: 60px;
  line-height: 60px;
  background: #fafafa;
}
.details_wrap {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 80px;
  left: 0;
  background-color: $white;
  // padding-top: 40px;
  overflow: hidden auto;

  .content {
    width: 1200px;
    margin: 0 auto;
    padding-bottom: 200px;
  }

  .state_exam {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    // width: 100%;
    height: 46px;
    background: #ffeeee;
    border-radius: 4px;
    padding: 0 16px;
    border: 1px solid #f04b4a;
    .left {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .el-icon-warning {
      color: #f04b4a;
      margin-right: 4px;
    }
    .right_btn {
      font-size: 20px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      cursor: pointer;
    }
    .icon {
    }
  }

  .height {
    height: 28px;
    overflow: hidden;
  }

  .show {
    // display: none;
    position: absolute;
    right: 0;
  }

  .details_class_info_btn {
    color: $theme_color;
    cursor: pointer;
    font-size: 16px;
    margin-right: 34px;
    width: 100px;
    text-align: right;
  }

  .details_content {
    position: relative;
  }

  .details_btns {
    position: absolute;
    right: 0;
    top: 9px;

    span {
      font-size: 18px;
      color: $font_color_grey_3;
      cursor: pointer;
      margin-left: 40px;

      &:hover,
      &:active {
        background: none;
        color: $font_color_grey_3;
      }

      & > i {
        color: $theme_color;
      }
    }
  }

  /deep/ .el-tabs__nav-wrap::after {
    display: none;
  }

  /deep/ .el-tabs__active-bar {
    display: none;
  }

  /deep/ .el-tabs__item {
    font-size: 18px;
    color: $font_color_grey_3;
  }

  /deep/ .el-tabs__item.is-active {
    color: $theme_color;
  }

  /deep/ .el-tabs__header {
    margin-bottom: 15px;
  }
}

.wrong_questions {
  cursor: pointer;
  width: 130px;
  height: 60px;
  background: #ffffff;
  // background: grba(255,255,255,0.6);
  border-radius: 8px;
  text-align: center;
  line-height: 60px;
  position: fixed;
  z-index: 100;
  box-shadow: 0 2px 16px #e9e9e9, 0 0 1px #e9e9e9, 0 0 1px #e9e9e9;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 40px;
  right: 20px;
  .el-icon-error {
    font-size: 30px;
    color: #f04b4a;
  }
  .text {
    font-size: 16px;
    font-weight: 400;
    color: #333333;
    line-height: 60px;
    margin-left: 6px;
  }
}
.icon_record{
  margin-left: 10px;
  cursor: pointer;
}
:deep(.select_lable){
  .el-dialog__body{
    padding-top: 20px;
    padding-bottom: 0;
    .items{
        height: 300px;
        overflow: auto;
      .item{
        margin-bottom: 14px;
        .title{
          font-size: 16px;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          display: block;
          margin-bottom: 10px;
        }
        .el-radio-button{
          margin-right: 10px;
          margin-bottom: 10px;
          background: #F8F8F8;
          border-radius: 4px;
          .el-radio-button__inner{
            border: none;
            border-radius: 4px;
          }
        }
        .el-radio-button.is-active{
          border: none;
          .el-radio-button__inner{
            color: #2D70F8;
            background: #EAF0FE;
            border: none;
            box-shadow:none;
          }
        }
      }
    }
  }
  .el-dialog__footer {
    text-align: right;
    .random_mode{
      color: #2D70F8;
      border-radius: 4px;
      border: 1px solid #2D70F8;
      background: #FFFFFF;
    }
  }
}
</style>