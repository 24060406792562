<template>
  <div class="picText"  style="text-align: center;">
    <div class="pic" >
      <el-image
      :preview-src-list="newImgList"
      id="d_picText"
      ref="d_picText"
        style="width:auto;height: 100%;max-width: 750px;"
        :src="localUrl"
        fit="fill">
      </el-image>
    </div>
    <div class="text">
      <div class="top">
        {{localDescribe}}
      </div>
      <div class="fun" v-if="imgTextList.length>1">
        <el-button type="primary" @click="prev" :disabled='!currentIndex'>上一个</el-button>
        <el-button type="primary" @click="next" :disabled='currentIndex == imgTextList.length-1'>下一个</el-button>
      </div>
    </div>
  </div>
  
</template>

<script>
import {toRaw} from '@vue/reactivity'
export default {
  data () {
    return {
      currentIndex:0,
      localUrl:'',
      localDescribe:'',
      imgList:[],
      newImgList: [],
    }
  },
  props: ['url' , 'type', 'fileType','describe','imgTextList'],
  // computed: {
  //   localUrl(){
  //     return this.url
  //   },
  //   localDescribe(){
  //     return this.describe
  //   }
  // },
  watch: {
    imgTextList:function(newVal, oldVal){
      if (newVal) {
        this.newImgList.length = 0
        newVal.forEach(element => {
          this.newImgList.push(element.url)
        });
        this.imgList = toRaw(newVal)
        if(this.imgList.length>0){
          this.localUrl = this.imgList[this.currentIndex].url
          this.localDescribe = this.imgList[this.currentIndex].describe
        }
      }
    }
  },
  created () {
    console.log('tt')
    console.log(this.imgTextList[0].url)
    this.imgTextList.forEach(element => {
      this.newImgList.push(element.url)
    });
    this.imgList = toRaw(this.imgTextList)
    if(this.imgList.length>0){
      this.localUrl = this.imgList[this.currentIndex].url
      this.localDescribe = this.imgList[this.currentIndex].describe
    }
  },
  mounted(){

  },
  methods: {
    next(){
      this.currentIndex++
      if(this.imgList.length > this.currentIndex ){
        this.localUrl = this.imgList[this.currentIndex].url
        this.localDescribe = this.imgList[this.currentIndex].describe
      }else{
        this.currentIndex = this.imgList.length -1
      }
    },
    prev(){
      this.currentIndex--
      if( this.currentIndex >= 0 ){
        this.localUrl = this.imgList[this.currentIndex].url
        this.localDescribe = this.imgList[this.currentIndex].describe
      }else{
        this.currentIndex = 0
      }
    },
  },
  
}
</script>

<style lang="scss" scoped>
.picText{
  padding: 30px 20px;
  // display: flex;
  .pic{
    width: 750px;
    text-align: center;
    background: #f8f8f8;
  }
  .text{
    width: 340px;
    margin-left: 20px;
    margin-top: 20px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .top{
      font-size: 20px;
      line-height: 36px;
    }
  }
}
</style>