<template>
  <div>
    <el-timeline class="class_info_wrap">
      <div v-if="type === 'curriculum'">
        {{}}
        <el-timeline-item v-for="item in list" :key="item.id">
          <div class="class_info_left">
            <span class="time">{{ item.timeRange }}</span>
            <!-- <h2 class="nameDAY">
              <el-tooltip
                class="item"
                effect="dark"
                :content="item.courseName"
                placement="top-start"
              >
                <p>{{ item.courseName.substring(0, 20) }}</p>
              </el-tooltip>
            </h2> -->
            <!-- <span class="name">{{ item.teaName }}</span> -->
            <!-- <span class="name" v-if="item.inClass === 0" style="color: red">未上课</span> -->
            <!-- <span class="name" v-if="item.inClass === 1" style="color: red">上课中
              <img src="../../../../assets/images/classing.png">
            </span> -->
          </div>
        <div class="class_info_right_box">
          <div class="class_title_name">
            <span class="class_title">{{ item.courseName.substring(0, 20) }}</span>
            <span class="class_name">{{ item.teaName }}</span>
            <span class="name" v-if="item.inClass === 1" style="color: red">上课中
              <img src="../../../../assets/images/classing.png">
            </span>
          </div>
          <div
            class="class_info_right"
            v-for="(v, i) in item.resRepoList"
            :key="i"
          >
            <div>
              <span class="tag tag_1" v-if="v.fileType === 1"></span>
              <span class="tag tag_2" v-if="v.fileType === 2"></span>
              <span class="tag tag_3" v-if="v.fileType === 3"></span>
              <span class="tag tag_4" v-if="v.fileType === 4"></span>
              <span class="tag tag_5" v-if="v.fileType === 5"></span>
              <span class="tag tag_6" v-if="v.fileType === 6"></span>
              <ul class="class_info_item">
                <li v-for="o in v.dataList" :key="o.id">
                  <span class="class_info_item_title">
                    {{ o.name }}
                    <span v-if="v.fileType !== 1"
                      >{{ o.totalQu > 0 ? `${o.totalQu}个` : "" }}{{
                        o.duration > 0 ? `/${o.duration}分钟` : ""
                      }}</span
                    >
                  </span>
                  <span class="class_info_item_btns">
                    <span
                      v-if="o.resRepoStatus && (v.fileType !== 2 && v.fileType !== 4 && v.fileType !== 5)"
                      style="color: #2d70f8;text-align: center;width:74px;padding-top:0;"
                      @click="seeView(o, v.fileType)"
                      >打开</span
                    >
                    <!-- 自测 -->
                    <span
                      v-if="o.resRepoStatus && v.fileType === 2"
                      style="color: #2d70f8;text-align: center;width:74px;padding-top:0;"
                      @click="handleOpenTest(o, item.courseConfId)"
                      >打开</span
                    >
                    <!-- 巩固和考试 -->
                    <el-button
                      v-if="
                        (v.fileType === 4 || v.fileType === 5) &&
                        o.resRepoStatus == 2
                      "
                      style="color: #f04b4a; border-color: #f04b4a"
                      @click="
                        handleOpenExamDialog(o, item.courseConfId, v.fileType)
                      "
                      >开始</el-button
                    >
                    <el-button
                      v-if="
                        (v.fileType === 4 || v.fileType === 5) &&
                        o.resRepoStatus == 3
                      "
                      style="color: #2d70f8; border-color: #2d70f8"
                      @click="handleJumpReview(o, item.courseConfId)"
                      >回顾</el-button
                    >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </div>
        </el-timeline-item>
      </div>

      <div class="teaching_program" v-if="type === 'outline'">
        <el-timeline-item v-for="item in list" :key="item.id">
          <div class="class_info_left">
            <span class="time" v-if="item.nameList.length > 1"
              >{{ item.nameList[0] }} -
              {{ item.nameList[item.nameList.length - 1] }}</span
            >
            <span class="time" v-else>{{ item.nameList[0] }}</span>
            <span
              v-for="(v, i) in item.teaList"
              :key="i"
              style="color: #9c9c9c; font-size: 16px"
            >
              {{ v.teaName }}<br />
            </span>
          </div>
          <div
            class="class_info_right"
            v-for="(v, i) in item.resRepoList"
            :key="i"
          >
            <div class="class_info_right_box">
              <span class="tag tag_1" v-if="v.fileType === 1"></span>
              <span class="tag tag_2" v-if="v.fileType === 2"></span>
              <span class="tag tag_3" v-if="v.fileType === 3"></span>
              <span class="tag tag_4" v-if="v.fileType === 4"></span>
              <span class="tag tag_5" v-if="v.fileType === 5"></span>
              <span class="tag tag_6" v-if="v.fileType === 6"></span>
              <ul class="class_info_item">
                <li v-for="(o, k) in v.dataList" :key="k">
                  <span v-if="v.fileType <= 3" class="nameTitle">
                    {{ o.name }}
                  </span>
                  <span v-if="v.fileType > 3" class="nameTitle">
                    {{ o.name }}
                    <span v-if="v.fileType !== 1"
                      >{{ o.totalQu > 0 ? `${o.totalQu}个` : "" }}/{{
                        o.duration > 0 ? `${o.duration}分钟` : "不限时间"
                      }}</span
                    >
                  </span>
                  <span class="class_info_item_btns">
                    <span
                      v-if="o.resRepoStatus && (v.fileType !== 2 && v.fileType !== 4 && v.fileType !== 5)"
                      style="color: #2d70f8;width:74px;text-align: center;padding-top:0;"
                      @click="seeView(o, v.fileType)"
                      >打开</span
                    >
                    <!-- 自测 -->
                    <span
                      v-if="o.resRepoStatus && v.fileType === 2"
                      style="color: #2d70f8;width:74px;text-align: center;padding-top:0;"
                      @click="handleOpenTest(o, o.gradeConfId)"
                      >打开</span
                    >
                    <!-- 巩固和考试 -->
                    <el-button
                      v-if="
                        (v.fileType === 4 || v.fileType === 5) &&
                        o.resRepoStatus == 2
                      "
                      style="color: #f04b4a; border-color: #f04b4a"
                      @click="
                        handleOpenExamDialog(o, item.resRepoList[i].dataList[k].gradeConfId, v.fileType)
                      "
                      >开始</el-button
                    >
                    <el-button
                      v-if="
                        (v.fileType === 4 || v.fileType === 5) &&
                        o.resRepoStatus == 3
                      "
                      style="color: #2d70f8; border-color: #2d70f8"
                      @click="handleJumpReview(o, item.courseConfId)"
                      >回顾</el-button
                    >
                  </span>
                </li>
              </ul>
            </div>
          </div>
        </el-timeline-item>
      </div>
    </el-timeline>

    <el-dialog v-model="dialogVisible" width="437px" height="600px">
      <div class="dialog_main" style="margin-bottom:130px;">
        <div>
          <div class="user_box" style="text-align:left;margin:0 20px;display:flex;margin-top:35px;">
            <el-image
              style="width: 50px; height: 50px"
              :src="details.headImg ? details.headImg : headImg"
            ></el-image>
            <div class="user_name">
              <h2 class="userName">{{ details.userName }}</h2>
              <h2 class="jobName">{{ details.jobName }}</h2>
            </div>
          </div>
          <div class="details_box">
            <div class="detail">
              <span>所属班级:</span> {{ details.gradeName }}
            </div>
            <div class="detail">
              <span>试卷名称:</span> {{ details.paperName }}
            </div>
            <div v-if="details.duration === '0'" class="detail">
              <span>考试标准:</span> {{ details.totalQu }}题/不限时间
            </div>
            <div v-else class="detail">
              <span>考试标准:</span> {{ details.totalQu }}题/{{
                details.duration
              }}分钟
            </div>
          </div>
        </div>
      </div>
      <template #footer style="margin-bottom:20px;">
        <span class="dialog-footer">
          <el-button
            type="primary"
            style="text-align: center"
            @click="handleOpenExam"
            class="start_exam"
            >开始考试</el-button
          >
        </span>
      </template>
    </el-dialog>

      <el-dialog
        customClass="file_preview"
        :title= title
        width="1200px"
        append-to-body
        :fullscreen="fullscreen"
        top="15vh"
        v-model="showDialog"
        :close-on-press-escape="false"
        @close="close"
        :show-close = !fullscreen
      >
        <div class="dialogCont" ref="dialogCont" id="stu_watermark">
          <!-- <span class="screen" @click="screen">{{
            !fullscreen ? "全屏" : "退出全屏"
          }}</span> -->
          <div style="min-height: 60vh;text-align: center;" v-if="dialogType == 'pdf'">
            <iframe
              :src="url"
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div
            style="min-height: 60vh;text-align: center;"
            v-if="
              dialogType == 'pptx' ||
              dialogType == 'xls' ||
              dialogType == 'xlsx' ||
              dialogType == 'doc' ||
              dialogType == 'docx'
            "
          >
            <iframe
              v-if="!fullscreen"
              style="height: 60vh"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                encodeURIComponent(url)
              "
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
            <iframe
              v-else
              style="height: 93vh"
              :src="
                'https://view.officeapps.live.com/op/view.aspx?src=' +
                encodeURIComponent(url)
              "
              frameborder="0"
              width="100%"
              height="100%"
            ></iframe>
          </div>
          <div style="min-height: 60vh;text-align: center;" v-if="dialogType == 'mp4'">
            <video
              loop="loop"
              style="width: 94%; height: 100%;text-align: center;"
              ref="video"
              :src="url"
              controls
            ></video>
          </div>
          <div
            class="img_box"
            style=""
            v-if="
              dialogType == 'png' ||
              dialogType == 'jpg' ||
              dialogType == 'jpeg' ||
              dialogType == 'gif'
            "
          >
            <picText
              :url="url"
              :type="bindType"
              :fileType="fileType"
              :describe="describe"
              :imgTextList="imgTextList"
            ></picText>
          </div>
        </div>
        <span class="screen_icon" @click="screen">
          <img class="full_screen" v-if="!fullscreen" src="../../../../assets/images/full_screen.png">
          <img class="reduction_screen" v-else src="../../../../assets/images/reduction_screen.png">
        </span>
      </el-dialog>
  </div>
</template>

<script>
import { STUDENTEXAM, StartStudy, EndStudy } from "@/server/details";
import picText from "@/views/Details/components/Details/picText";
import watermark from "../../../../utils/watermark"
export default {
  data() {
    return {
      dialogVisible: false,
      details: {},
      obj: {},
      activities: [
        {
          content: "支持使用图标",
          timestamp: "2018-04-12 20:46",
          size: "large",
          type: "primary",
          icon: "el-icon-more",
        },
        {
          content: "支持自定义颜色",
          timestamp: "2018-04-03 20:46",
          color: "#0bbd87",
        },
        {
          content: "支持自定义尺寸",
          timestamp: "2018-04-03 20:46",
          size: "large",
        },
        {
          content: "默认样式的节点",
          timestamp: "2018-04-03 20:46",
        },
      ],
      showDialog: false,
      fullscreen: false,
      // 文件后缀
      dialogType: "",
      // 文件地址
      url: "",
      // 文件类型
      bindType: "",
      // 课件类型
      fileType: "",
      // 图文描述
      describe: "",
      // 图文列表
      imgTextList: [],
      // 图文标题
      title: "",
      curBussId: "",
      headImg: require("@/assets/images/header.png"),
    };
  },
  components: {
    picText,
  },
  props: ["list", "type", "outLineList", "gradeId"],
  methods: {
    // 点击播放
    play() {},
    // 自测
    handleOpenTest(val, courseConfId) {
      let obj = {
        gradeConfId: courseConfId,
        gradeId: this.gradeId,
        paperId: val.id,
        duration: val.duration,
      };
      // debugger
      this.$router.push({ path: "/selftest", query: obj });
    },
    // 考试
    handleOpenExam() {
      this.dialogVisible = false;
      this.$router.push({ path: "/examination", query: this.obj });
    },
    handleOpenExamDialog(val, courseConfId, fileType) {
      this.dialogVisible = true;
      this.obj = {
        gradeConfId: courseConfId, //this.$route.query.gradeConfId,
        gradeId: this.gradeId,
        paperId: val.id,
        duration: val.duration,
        fileType: fileType,
      };
      // 获取用户信息
      STUDENTEXAM({
        courseConfId: courseConfId,
        gradeId: this.gradeId,
        paperId: val.id,
        userId: JSON.parse(window.sessionStorage.getItem("loginInfo")).eId,
        examType: fileType,
      }).then((res) => {
        if (res.data.code === 0) {
          this.details = res.data.data;
          console.log(res.data.data)
        }
      });
    },
    hideIframe() {
      this.previewBili = false;
    },
    // 点击查看
    seeView(obj, fileType, fullscreen) {
      // this.$nextTick(()=>{
      //   console.log(this.$refs.abc)
      // })
      // b站判断
      if (obj.type == 3) {
        let url1 = obj.url.replace("//","https://")
        // // console.log(url1)
        this.$alert(url1, '文件预览', {
          dangerouslyUseHTMLString: true,
          showConfirmButton:false,
          customClass:'iframeBox'
        })
      } else {
        if (obj.url) {
          this.dialogType = obj.url.substring(
            obj.url.lastIndexOf(".") + 1,
            obj.url.length
          );
        }
        this.url = obj.url;
        this.title = obj.name;
        this.bindType = obj.type;
        this.describe = obj.describe;
        this.imgTextList = obj.imgTextList;
        this.fileType = fileType;
        this.showDialog = true;
        this.fullscreen = fullscreen ? fullscreen : false;
      }
      console.log(obj);
      // console.log({
      //           "bussId": obj.id,
      //           "bussType": 1,//学习类型(1预习 2自测 3课件 4巩固 5考试)
      //           "gradeConfId": this.$route.query.gradeConfId,
      //           "gradeId": this.$route.query.id,//班级id
      //           // "studyTime": 0
      //         })
      //         return false;

      this.curBussId = obj.id;
      // 开始学习
      StartStudy({
        bussId: this.curBussId,
        bussType: 1, //学习类型(1预习 2自测 3课件 4巩固 5考试)
        gradeConfId: this.$route.query.gradeConfId,
        gradeId: this.$route.query.id, //班级id
        // "studyTime": 0
      }).then((res) => {
        if (res.data.code === 0) {
          //this.details = res.data.data
        }
      });
    },
    // 关闭弹窗
    close() {
      this.url = "";
      this.bindType = "";
      this.describe = "";
      this.imgTextList = "";
      this.fileType = "";
      console.log('close')
      // this.$refs.video.pause() 注解原因：教学大纲打开图片 无法关闭（报错）

      //结束学习
      EndStudy({
        bussId: this.curBussId,
        bussType: 1, //学习类型(1预习 2自测 3课件 4巩固 5考试)
        gradeConfId: this.$route.query.gradeConfId,
        gradeId: this.$route.query.id, //班级id
        // "studyTime": 0
      }).then((res) => {
        if (res.data.code === 0) {
          //this.details = res.data.data
        }
      });
      this.fullscreen = false;
    },
    // 全屏展示
    screen() {
      let element = document.documentElement;
      if (this.fullscreen) {
        if (document.exitFullscreen) {
          document.exitFullscreen();
        } else if (document.webkitCancelFullScreen) {
          document.webkitCancelFullScreen();
        } else if (document.mozCancelFullScreen) {
          document.mozCancelFullScreen();
        } else if (document.msExitFullscreen) {
          document.msExitFullscreen();
        }
      } else {
        if (element.requestFullscreen) {
          element.requestFullscreen();
        } else if (element.webkitRequestFullScreen) {
          element.webkitRequestFullScreen();
        } else if (element.mozRequestFullScreen) {
          element.mozRequestFullScreen();
        } else if (element.msRequestFullscreen) {
          // IE11
          element.msRequestFullscreen();
        }
      }
      this.fullscreen = !this.fullscreen;
    },
    // 回顾
    handleJumpReview(val, courseConfId) {
      const obj = {
        gradeConfId: courseConfId,
        gradeId: this.gradeId,
        paperId: val.id,
      };
      this.$router.push({ path: "/review", query: obj });
    },
  },
  created() {
    console.log("info.vue:" + this.list);
  },
  mounted(){
    var loginInfo_name = JSON.parse(sessionStorage.getItem('loginInfo')).realName
    var loginInfo_mobile = JSON.parse(sessionStorage.getItem('loginInfo')).mobile.substr(7,4)
    watermark.set(loginInfo_name + loginInfo_mobile, this.$refs.dialogCont)
  },
};
</script>
<style lang="scss">
.el-dialog__footer{
  text-align: center;
}
.iframeBox {
  width: 800px !important;
  height: 600px !important;
  text-align: center;
}
iframe {
  width: 90%;
  // min-height: 88vh;
}
.img_box{
  .el-image__inner{
    // width: 360px;
  }
}
.img_box{
  .picText{
    display: flex;
    align-items: flex-start;
    text-align: left !important;
    .text{
      margin-left: 30px;
      margin-top: 0;
    }
  }
}
.file_preview{
  // min-height: 670px;
  // width: auto!important;
  .el-dialog__header{
    height: 40px;
    background: #f8f8f8;
    text-align: center;
    line-height: 40px;
    padding: 0;
    .el-dialog__title{
      font-size: 16px;
      font-weight: 500;
      color: #333333;
    }
    .el-dialog__headerbtn{
      top: 13px;
    }
  }
  .el-dialog__body{
    padding: 0;
    margin: 0;
  }
}
</style>
<style lang="scss" scoped>
@mixin Tag($color: #2e7fff, $text: "课件") {
  background-color: $color;

  &::after {
    content: $text;
  }
}
.class_info_wrap {
  .el-timeline-item__wrapper {
    padding-left: 0;
  }

  .el-timeline-item {
    min-height: 80px;
    padding-bottom: 16px;
  }

  .el-timeline-item__node {
    width: 10px;
    height: 10px;
    background-color: #fff;
    border: 1px solid #e6e6e6;
    top: 5px;
  }

  /deep/ .el-timeline-item__node--normal {
    left: 118px;
    top: 5px;
    border: 2px solid #dddddd;
    background: #fff;
  }
}

.class_info_left {
  // position: absolute;
  float: left;
  margin-top: 18px;
  span {
    display: block;
    width: 85px;
  }
  .time {
    color: #9c9c9c;
  }
}
.teaching_program{
  .class_info_left{
    margin-top: 8px;
  }
}


.class_info_right {
  margin-left: 128px;
  padding-top: 4px;
  padding-bottom: 16px;
  // border-bottom: 1px solid #e6e6e6;
  .class_info_right_box{
    // align-items: baseline;
  }
  div {
    display: flex;
    align-items: center;
    margin-block-start: 0;
    margin-block-end: 0;
    justify-content: space-between;
    // align-items: center;
    margin-bottom: 16px;
    color: $font_color_grey_3;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .class_info_item {
    flex: 1;
    li {
      font-size: 16px;
      // margin-top: -7px;
      // margin-bottom: 24px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .class_info_item_title{
        width: 760px;
      }
      span {
        // padding-top: 9px;
        box-sizing: border-box;
      }
      .nameTitle {
        margin-top: 0px;
        width: 760px;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
  .class_info_item_text {
    width: 100%;
  }
  .class_info_item_btns {
    width: 120px;
    position: relative;
    left: 38px;
    & > span {
      cursor: pointer;
      display: inline-block;
      width: 80px;
    }

    .el-button {
      padding: 0;
      width: 74px;
      height: 34px;
      line-height: 32px;
      font-size: 16px;
      min-height: auto;
    }
    .el-button:hover{
      background-color: #ffeeee;
    }
  }

  .class_info_item_btn_play {
    background-color: $theme_color;
    color: $white;
    // margin-top: 13px;
  }

  .class_info_item_btn_submit {
    border: 1px solid #f04b4a;
    background: #fff;
    color: #f04b4a;
    // margin-top: 13px;
  }

  .class_info_text {
    display: inline-block;
    position: relative;
    padding-right: 10px;
    margin-right: 10px;
    height: 20px;
    &::after {
      content: "";
      position: absolute;
      width: 1px;
      height: 16px;
      background-color: #262626;
      top: 5px;
      right: 0;
    }

    &:last-child::after {
      display: none;
    }
  }

  .class_info_gray {
    color: #9a9a9a;
  }

  .tag {
    display: inline-block;
    width: 40px;
    height: 24px;
    background-color: #969da8;
    color: $white;
    margin-right: 8px;
    text-align: center;
    line-height: 24px;
    // margin-top: 10px;
  }

  .tag_1 {
    @include Tag(#969da8, "预习");
  }

  .tag_2 {
    @include Tag(#969da8, "自测");
  }

  .tag_3 {
    @include Tag();
  }

  .tag_4 {
    @include Tag(#33baf2, "巩固");
  }

  .tag_5 {
    @include Tag(#f04b4a, "考试");
  }

  .tag_6 {
    @include Tag(#ff9419, "陪练");
  }
}
.class_info_right:hover, .class_title_name:hover{
  background: #F1F1F1;
}
.class_title_name{
  margin-left: 115px;
  padding-top: 20px;
  padding-left: 23px;
  height: 40px;
  .class_title{
    font-size: 16px;
    font-weight: 500;
    color: #333333;
    line-height: 22px;
  }
  .class_name{
    font-size: 16px;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
    margin-left: 10px;
  }
  .name{
    font-size: 16px;
    margin-left: 10px;
  }
}

.dialogCont {
  position: relative;
  .screen {
    position: absolute;
    bottom: 20px;
    right: 20px;
    cursor: pointer;
    background: #fff;
    padding: 10px 14px;
    border: 1px solid #000;
    border-radius: 6px;
  }
}
.screen_icon{
  position: absolute;
  top: 12px;
  right: 40px;
  cursor: pointer;
  img{
    width: 20px;
    height: 20px;
  }
  .reduction_screen{
    position: absolute;
    right: -30px;
  }
}
.user_box {
  margin-bottom: 20px;
  text-align: center;
}
.detail {
  line-height: 30px;
  span {
    width: 65px;
  }
}
.nameDAY {
  // width: 83px;
  font-size: 15px;
  word-wrap: break-word;
}
</style>
<style scoped lang="scss">
.dialog_main{
  .user_name{
    margin-left: 16px;
    .userName{
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
    }
    .jobName{
      font-size: 14px;
      font-weight: 400;
      color: #999999;
      line-height: 20px;
    }
  }
  .details_box{
    padding: 0px 20px;
    margin-top: 24px;
    .detail{
      font-size: 16px;
      font-weight: 400;
      color: #333333;
      line-height: 28px;
      margin-bottom: 16px;
    }
  }
}
.dialog-footer{
  height: 125px;
  display: inline-block;
}
.start_exam{
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF;
  width: 150px;
  height: 46px;
  background: #2E7FFF;
  border-radius: 4px;
}
.el-dialog__footer{
  margin: 98px;
}
</style>